<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Choices from "choices.js";


import {getBrokerageApi} from "@/api/brokerage"
import {uploadFile} from '@/api/misc'



 import {
  required,
} from "vuelidate/lib/validators";


/**
 * New Staff component
 */
export default {
  page: {
    title: "Office Edit",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props: {
     office : {
      type : Object,
      default: () => ({
        managers: []
      })
     },
    
  },

  data() {
    return {
      title: "Brokerage",
      items: [
        {
          text: "Office",
          href: "/brokerage/office/grid",
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      edit : false,
      manager_choice: {},
      conveyance_choic: {},
      manager_list: [],
      conveyancer_list : [],
    };
  },


  components: {
    Layout,
    PageHeader,
  },

  validations()  {
      if (this.edit) {
        return {
          office: {
              name: { required },
              branch_name: { required },
          }
        }
      } else {
        return {
          office: {
              name: { required },
              branch_name: { required },
          },
        }
    }
  },

  methods: {

    formSubmit() {

      let new_managers = []
      let new_conveyancers= []
      if (this.office.managers) {
        this.office.managers.map((m) =>{
          new_managers.push(this.manager_list.find((e) => e.value == m).staff)
        })
      }


      if (this.office.conveyancers) {
        this.office.conveyancers.map((m) =>{
          new_conveyancers.push(this.conveyancer_list.find((e) => e.value == m).staff)
        })
      }

      this.office.managers =  new_managers
      this.office.conveyancers = new_conveyancers

      this.$v.$touch();     
      if (this.$v.$error == false) { 
        
          getBrokerageApi().officeApi.office_edit(this.office).then((res) => {
            if (res.errCode == 0) {
              this.$router.push({ path: '/brokerage/office/grid' })
            } else {
              this.$alertify.error("Operation  Failed "+ res.errCode);
            }
          })
      }
    },

    choosePicture() {
      this.$refs.avatarFile.click()
    },

    pictureChanged(evt) {
      if (evt.target.files.length <=0) {
          return
      }
      let formData = new FormData()
      formData.append("file", evt.target.files[0]);
      uploadFile(formData).then((res) =>{
          if (res[0].errCode == 200) {
              this.office.team_pic = res[0].response.url
          } 
      })
    },

   
  },

  beforeUpdated() {
    
  },

  mounted() {
   
    this.manager_choice = new Choices('#choices-managers-input', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })


    this.conveyance_choice = new Choices('#choices-conveyance-input', {
        placeholderValue: "This is a placeholder set in the config",
        searchPlaceholderValue: "search by name",
    })

    


   
    getBrokerageApi().staffApi.list().then( (res) => {

        res.data.map((f)=>{
          let selected = this.office.managers.find((e)=> e.staff_id == f.id)
          let selected_c = this.office.conveyancers.find((e)=> e.staff_id == f.id)
          this.manager_list.push({
            label : f.full_name,
            value : f.id,
            selected : selected,
            staff : f
          })

          this.conveyancer_list.push({
            label : f.full_name,
            value : f.id,
            selected : selected_c,
            staff : f
          })
        })
      
        
        this.manager_choice.setChoices(this.manager_list)
        this.conveyance_choice.setChoices(this.conveyancer_list)
    })


    

    this.edit = this.office.id ? true : false
   

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">Office</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">General Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Office Name</label>
                          <input type="text" class="form-control" v-model="office.name" id="gen-info-name-input" placeholder="Enter Name"  
                            :class="{
                                    'is-invalid':  $v.office.name.$error,
                                }" 
                          />
                          <div v-if=" $v.office.name.$error" class="invalid-feedback">
                              <span v-if="!$v.office.name.required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Office Branch Name</label>
                          <input type="text" class="form-control" v-model="office.branch_name" id="gen-info-name-input" placeholder="Enter Name"  
                            :class="{
                                    'is-invalid':  $v.office.branch_name.$error,
                                }" 
                          />
                          <div v-if=" $v.office.branch_name.$error" class="invalid-feedback">
                              <span v-if="!$v.office.branch_name.required">This value is required.</span>
                          </div>
                        </div>
                      </div>           
                    </div>
                    <!-- End row-->


                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Board Code</label>
                          <input type="text" class="form-control" v-model="office.office_code" id="gen-info-name-input" placeholder="Enter Office Code"  
                          />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">BCFSA Code</label>
                          <input type="text" class="form-control" v-model="office.bcfsa_code" id="gen-info-name-input" placeholder="Enter Bcfsa Code"  
                          />
                        </div>
                      </div>           
                    </div>
                    <!-- End row-->

                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Phone 1</label>
                          <input type="text" class="form-control" v-model="office.phone1" id="gen-info-name-input" placeholder="Enter Name"  
                          />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Phone 2</label>
                          <input type="text" class="form-control" v-model="office.phone2" id="gen-info-name-input" placeholder="Enter Name"  
                          />
                        </div>
                      </div>           
                    </div>
                    <!-- End row-->


                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Fax </label>
                          <input type="text" class="form-control" v-model="office.fax"  placeholder="Enter Fax"  
                          />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Email</label>
                          <input type="text" class="form-control" v-model="office.office_email"  placeholder="Enter Email"  
                          />
                        </div>
                      </div>           
                    </div>
                    <!-- End row-->

                     <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-adress-input">Office Address</label>
                          <input type="text" class="form-control" v-model="office.address" id="gen-info-adress-input" placeholder="Enter Address"  
                          />
                        </div>
                      </div>
                    </div>

                    <div  class="row">
                      <div class="col-lg-6 text-center">
                        <input  ref="avatarFile"  class="form-control" @change="pictureChanged" type="file" id="avatarFile" style="opacity: 0;width : 1px; height:1px" />
                        <div class="position-relative d-inline-block mx-auto">
                          <div >
                            <img :src="office.team_pic" alt="" class="img-thumbnail" />
                          </div>
                          <a href="#" :class="{disabled: true}" class="d-block position-absolute bottom-0 end-0" @click="choosePicture">
                            <div class="avatar-sm">
                              <div class="avatar-title rounded-circle bg-light text-primary">
                                <i class="mdi mdi-pencil"> </i>
                              </div>
                            </div>
                          </a>
                          
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-description-input">Description</label>
                        <textarea class="form-control" v-model="office.descriptions" placeholder="Enter Description" id="gen-info-description-input"
                          rows="3"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->



              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Property Manager</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="mb-3">

                      <label for="choices-managers-input" class="form-label">Property Managers</label>
                      <select class="form-control" 
                        v-model="office.managers" name="choices-managers-input" 
                        id="choices-managers-input" multiple>
                      </select>

                      </div>
                    </div>                   
                  </div>
                 
                </div>
              </div>
              <!-- end card -->



              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        03
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Conveyance</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="mb-3">

                      <label for="choices-conveyance-input" class="form-label">Conveyancer</label>
                      <select class="form-control" 
                        v-model="office.conveyancers" name="choices-conveyance-input" 
                        id="choices-conveyance-input" multiple>
                      </select>

                      </div>
                    </div>                   
                  </div>
                 
                </div>
              </div>
              <!-- end card -->

              <div class="text-end">
                <button type="submit" class="btn btn-primary w-sm">
                  Submit
                </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
